import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67')
];

export const server_loads = [2,6];

export const dictionary = {
		"/(app)": [9,[2],[3]],
		"/(app)/accelerator/onboarding": [11,[2],[3]],
		"/analytics": [65],
		"/(app)/(admin)/announcements": [10,[2],[3]],
		"/(app)/companies": [12,[2],[3]],
		"/(app)/companies/edit/[slug]": [~14,[2],[3]],
		"/(app)/companies/new": [~15,[2],[3]],
		"/(app)/companies/[slug]": [13,[2,4],[3]],
		"/components": [66],
		"/(auth)/email-confirmation": [50],
		"/(auth)/email-confirmation/failed": [51],
		"/(auth)/email-confirmation/request-verification": [52],
		"/(auth)/email-confirmed": [53],
		"/(app)/forum/[id=forum]": [~16,[2],[3]],
		"/(app)/founders": [17,[2],[3]],
		"/(app)/hackathon": [~18,[2],[3]],
		"/(app)/hackathon/dev": [~19,[2],[3]],
		"/(app)/investors": [20,[2],[3]],
		"/(app)/investors/edit/[slug]": [~23,[2],[3]],
		"/(app)/investors/new": [~24,[2],[3]],
		"/(app)/investors/review": [25,[2],[3]],
		"/(app)/investors/review/[slug]": [~26,[2],[3]],
		"/(app)/investors/review/[slug]/edit/[id]": [~27,[2],[3]],
		"/(app)/investors/[slug]": [21,[2,5],[3]],
		"/(app)/investors/[slug]/reviews": [22,[2,5],[3]],
		"/login": [67],
		"/(app)/messages": [28,[2],[3]],
		"/(auth)/oauth-callback/github": [54],
		"/(auth)/oauth-callback/google": [55],
		"/(app)/onboarding": [~29,[2],[3]],
		"/(app)/posts": [~30,[2],[3]],
		"/(app)/posts/[id]": [~31,[2,6],[3]],
		"/(app)/posts/[id]/comments/[commentId]": [32,[2,6],[3]],
		"/(app)/profiles": [33,[2],[3]],
		"/(app)/profiles/[username]": [34,[2,7],[3]],
		"/(app)/profiles/[username]/activity": [~35,[2,7,8],[3]],
		"/(app)/profiles/[username]/activity/comments": [36,[2,7,8],[3]],
		"/(app)/projects": [~37,[2],[3]],
		"/(app)/projects/editor": [~38,[2],[3]],
		"/(app)/projects/ended": [39,[2],[3]],
		"/(app)/projects/explore": [40,[2],[3]],
		"/(app)/projects/explore/[projectId]": [41,[2],[3]],
		"/(app)/projects/hackathon/[slug]": [42,[2],[3]],
		"/(app)/projects/submit": [~43,[2],[3]],
		"/(app)/projects/success": [44,[2],[3]],
		"/(app)/referrals": [45,[2],[3]],
		"/(auth)/refresh-session": [56],
		"/(app)/register": [~46,[2],[3]],
		"/(app)/reports": [47,[2],[3]],
		"/(auth)/reset-password": [57],
		"/(auth)/reset-password/link-expired": [58],
		"/(auth)/reset-password/link-sent": [59],
		"/(auth)/reset-password/new": [60],
		"/(auth)/reset-password/success": [61],
		"/(app)/settings": [48,[2],[3]],
		"/(auth)/signin": [62],
		"/(auth)/signin/verify-magic-link": [63],
		"/(auth)/signup": [64],
		"/(app)/support": [49,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';